import type { CategorySlugItem } from "~/server/api/ecom/categories/index.get";

export async function useCategories() {
  const nuxtApp = useNuxtApp();
  const { locale } = useI18n();

  const { data } = await useFetch<Record<string, CategorySlugItem>>("/api/ecom/categories", {
    query: { locale: locale },
    getCachedData: key => nuxtApp.payload.data[key] ?? nuxtApp.static.data[key],
  });

  return data;
}
